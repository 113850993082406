import React, { Component } from "react"
import { Section, QuickInfoWrapper } from "../../utils"

export default class JellyBotsInfo extends Component {
  render() {
    return (
      <Section style={{ width: "100%" }}>
        <QuickInfoWrapper>
          <p className="text">
            <u>Description:</u>
            <br />
            Help the robots get to the red Jelly!
            <br />
            <br />
          </p>
          <p className="controllerText">
            <u>Instructions:</u>
            <br />
            Hold left mouse button to slow time.
            <br />
            Release left mouse button to throw robots. <br />
            Quick click to get more speed. <br />
            Restart: R<br />
            Mute Sound: M<br />
            Menu: Escape
            <br />
          </p>
        </QuickInfoWrapper>
      </Section>
    )
  }
}
